import { get, post } from './http'

export const getOrder = (params) => {
  const url = '/mobile/order'
  return get(url, params)
}

export const cancelOrder = (data) => {
  const url = '/mobile/order/cancel'
  return post(url, data)
}

export const deleteOrder = (data) => {
  const url = '/mobile/order/delete'
  return post(url, data)
}

export const resubmitOrder = (data) => {
  const url = '/mobile/order/re_submit'
  return post(url, data)
}

export const orderInfo = (params) => {
  const url = '/buy/order_info'
  return get(url, params)
}

export const cartNum = (params) => {
  const url = '/buy/cart_count'
  return get(url, params)
}

export const addCart = (data) => {
  const url = '/buy/cart_add'
  return post(url, data)
}

export const cartShow = () => {
  const url = '/buy/cart_show'
  return get(url)
}

export const deleteCart = (data) => {
  const url = '/buy/cart_del'
  return post(url, data)
}

export const submitOrder = (data) => {
  const url = '/buy/submit_order'
  return post(url, data)
}

export const affirmPay = (data) => {
  const url = '/buy/affirm_pay'
  return post(url, data)
}

export const orderRefund = (data) => {
  const url = '/mobile/order/refund'
  return post(url, data)
}

export const orderRefundInfo = (params) => {
  const url = '/mobile/order/refund_detail'
  return get(url, params)
}

export const orderRefundCancel = (data) => {
  const url = '/mobile/order/cancel_refund'
  return post(url, data)
}
